.tabContentWrapper {
  padding: 40px;
  box-sizing: border-box;
}

.tabContentPagination {
  padding: 40px 40px 100px 40px;
  box-sizing: border-box;
}

.block {
  margin-bottom: 40px;
}

.smallBlock {
  width: 50%;
}

.container {
  position: relative;
}

.headerBlockSpace {
  display: flex;
  justify-content: space-between;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.blockCenter {
  display: flex;
  align-items: center;
}

.mainContent {
  background-color: #f9fafc;
  min-height: calc(100vh - 120px);
  position: relative;
}

@media (max-width: 767px) {
  .tabContentWrapper {
    padding: 20px;
  }

  .block {
    margin-bottom: 15px;
  }

  .tabContentPagination {
    padding: 20px 20px 80px 20px;
  }

  .smallBlock {
    width: 100%;
  }
}
