@font-face {
    font-family: 'Futura Book';
    src: url('assets/fonts/FuturaBook.ttf');
}

@font-face {
  font-family: 'Futura Bold';
  src: url('assets/fonts/FuturaBold.otf');
}

@font-face {
  font-family: 'Futura Medium';
  src: url('assets/fonts/FuturaMedium.otf');
}

@font-face {
  font-family: 'Futura Heavy';
  src: url('assets/fonts/FuturaHeavy.ttf');
}

@font-face {
  font-family: 'Futura Bold Italic';
  src: url('assets/fonts/FuturaBoldItalic.ttf');
}

@font-face {
  font-family: 'Futura Condensed Extra Bold Italic';
  src: url('assets/fonts/FuturaCondensedExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'Futura Extra Bold';
  src: url('assets/fonts/FuturaExtraBold.ttf');
}

@font-face {
  font-family: 'Futura Bold Regular';
  src: url('assets/fonts/FuturaBoldRegular.ttf');
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham.otf');
}

body {
  margin: 0;
  font-family: 'Futura Medium', sans-serif;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

a {
  text-decoration: none;
}

*,
::after,
::before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
/* styles for range slider */
.rangeslider,
.rangeslider .rangeslider__fill {
  box-shadow: none !important;
  border: 1px solid #f0f0f0 !important;
}
.rangeslider {
  margin: 0 !important;
  height: 20px !important;
  background: #353535 !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #f1c400 !important;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  border: 2px solid #f0f0f0 !important;
  background: #d8d8d8 !important;
  box-shadow: none !important;
}
.rangeslider-horizontal .rangeslider__handle:after {
  content: '' !important;
  width: 26px !important;
  height: 26px !important;
  top: -2px !important;
  left: -2px !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  background-image: url('assets/icons/rangeslider_handle.png') !important;
  background-size: contain;
  box-shadow: none !important;
}
@media (max-width: 767px) {
  fieldset {
    width: 100%;
  }
}
