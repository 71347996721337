.wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperBody {
  margin: 40px 0 36px 0;
  width: 400px;
}

.wrapperContent {
  margin-bottom: 20px;
}

.back {
  margin-right: 7px;
  transform: rotate(180deg);
}
.contentPage {
  /* background: url(../../assets/backgrounds/roofclaim_background.jpeg) no-repeat; */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-size: cover;
}

@media (max-width: 767px) {
  .contentPage {
    background: none no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto;
  }
}

@media (max-width: 767px) {
  .wrapperBody {
    width: 100%;
  }
}
