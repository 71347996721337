.emailConfirmText {
  text-align: center;
  padding-top: 20px;
}
.contentPage {
  /* background: url(../../assets/backgrounds/roofclaim_background.jpeg) no-repeat; */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-size: cover;
}

@media (max-width: 767px) {
  .contentPage {
    background: none no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: auto;
  }
}
