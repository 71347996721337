@media (min-width: 768px) {
  .projectCardWrapper {
    width: 300px;
    min-height: 120px;
    background: hsl(0, 0%, 100%);
    border-radius: 3px;
    padding: 20px 20px 10px 20px;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #eeeeee;
    cursor: pointer;
  }

  .projectCardWrapperSelected {
    border-left: 4px solid #000;
  }
}

.calendarIcon {
  margin-right: 10px;
  filter: invert(45%) sepia(1%) saturate(0%) hue-rotate(300deg) brightness(103%)
    contrast(90%);
}
.projectCardFooter {
  position: absolute;
  right: 30px;
  bottom: 10px;
}
@media (max-width: 767px) {
  .projectCardWrapper {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 20px 20px 45px 20px;
  }
  .statusWrapper {
    display: flex;
    align-items: center;
  }
  .mobileTableRowFlex {
    display: flex;
    margin-right: 10px;
  }

  .infoIcon {
    margin-left: 8px;
  }

  .projectCardHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  .calendarIcon {
    margin-right: 10px;
    filter: invert(45%) sepia(1%) saturate(0%) hue-rotate(300deg) brightness(103%)
      contrast(90%);
  }

  .mobileTableRow {
    position: relative;
    background: #ffffff;
    border-radius: 4px;
    border-right: 1px solid #d8dadf;
    border-top: 1px solid #d8dadf;
    border-bottom: 1px solid #d8dadf;
    border-left: 1px solid #d8dadf;
  }
  .mobileTableRowPrimary {
    border-left: 4px solid #f3c306;
  }

  .mobileTableRowSecondary {
    border-left: 4px solid #000;
  }

  .mobileTableRowVertical {
    margin-bottom: 10px;
    position: relative;
  }

  .mobileTableRowHorizontal {
    margin-right: 10px;
  }

  .mobileTableRowContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .projectCardWrapperSelected {
    border-left: 4px solid #000;
  }

  .textOverflow {
    white-space: initial;
  }
}

@media (max-width: 340px) {
  .mobileTableRowContent {
    display: block;
  }

  .mobileTableRowContent button {
    margin-top: 5px;
  }
  .projectCardHeader {
    display: block;
  }
  .statusWrapper {
    padding: 5px 0 0;
  }
}
