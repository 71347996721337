.container {
  display: flex;
  flex-direction: column;
  max-width: 1190px;
  width: 100%;
}

.modalMainContent {
  text-align: center;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.modalMainContentLeft {
  text-align: left;
  width: 100%;
}

.formButton {
  margin-top: 40px;
}

.modalMainContentHeader {
  margin-bottom: 20px;
}

.closeIcon {
  cursor: pointer;
  right: 20px;
  top: 20px;
  position: absolute;
}

.questionIcon {
  filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(13deg) brightness(112%)
    contrast(101%);
}

.modalContent {
  margin: 40px 0;
  text-align: center;
}

.modalContentSmall {
  text-align: center;
  margin: 40px 0 0;
}

@media (max-height: 568px) {
  .formButton {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .modalMainContent {
    padding: 0 20px;
    text-align: center;
    justify-content: flex-start;
    max-width: 100%;
  }

  .modalHeaderTitle {
    text-align: center;
    padding: 0 10px;
    line-height: 25px;
  }

  .formButton {
    margin-top: 55px;
    width: 100%;
  }

  .questionIcon {
    margin: 0 0 20px 0;
  }

  .modalContent {
    margin: 0;
  }
}
